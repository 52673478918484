import Vue from 'vue'
import Vuex from 'vuex'
import apiClient from '@/services/axios'

Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    data: [],
    detail: {},
    form: {
      name: '',
      description: '',
    },
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    CLEAR_DATA(state) {
      state.data = []
    },
    CLEAR_FORM(state) {
      state.form = {
        name: '',
        description: '',
      }
    },
  },
  actions: {
    FETCH({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get('/v1/permissions', {
            params: payload,
          })
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
                data: response.data.data.map((permission) => {
                  return {
                    ...permission,
                    label: permission.name,
                    value: permission.id,
                    key: permission.id,
                  }
                }),
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    FETCH_ONE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/permissions/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
                form: response,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    EDIT({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/permissions/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
                form: {
                  ...response.data,
                },
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    STORE({ commit, dispatch, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })
      // rootState.permission.form.portals.push(rootState.settings.activePortal)

      return new Promise((resolve) => {
        apiClient
          .post('/v1/permissions', rootState.permission.form)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            }
            commit('SET_STATE', {
              loading: false,
            })
            resolve(false)
          })
          .catch(err => console.log(err))
      })
    },
    UPDATE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      const form = rootState.permission.form

      const newData = {
        ...form,
      }

      return new Promise((resolve) => {
        apiClient
          .put(`/v1/permissions/${payload}`, newData)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    DELETE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .delete(`/v1/permissions/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
  },
}
