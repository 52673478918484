import axios from 'axios'
import store from 'store'
// import { notification } from 'ant-design-vue'
const API_HOST = process.env.VUE_APP_API_URL || 'https://dev-api-cms.kmbmedia.id'
const API_URL = API_HOST + '/api'
const apiClient = axios.create({
  baseURL: API_URL,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

// apiClient.interceptors.response.use(undefined, error => {
//   // Errors handling
//   const { response } = error
//   const { data } = response
//   if (data) {
//     notification.warning({
//       // message: data,
//       message: 'Not authenticated',
//     })
//   }
// })

export default apiClient
