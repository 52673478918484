<template>
  <div :class="$style.topbar">
    <!-- <div class="mr-4">
      <cui-fav-pages />
    </div> -->
    <!-- <div class="mr-auto">
      <cui-search />
    </div> -->
    <!-- <div class="mr-4 d-none d-md-block">
      <cui-issues-history />
    </div> -->
    <div class="mb-0 mr-auto">
      <cui-project-management />
    </div>
    <Acl :roles="['administrator', 'superadmin']" class="mr-4">
      <b-button size="sm" @click="clearCache" variant="outline-warning">
        <i class="fe fe-server"></i> Clear Cache
      </b-button>
    </Acl>
    <!-- <div class="mr-4 d-none d-sm-block">
      <cui-actions />
    </div> -->
    <div>
      <cui-user-menu />
    </div>
  </div>
</template>

<script>
import Acl from '@/components/cleanui/system/ACL'
// import CuiIssuesHistory from './IssuesHistory'
import CuiProjectManagement from './ProjectManagement'
// import CuiSearch from './Search'
// import CuiLanguageSwitcher from './LanguageSwitcher'
// import CuiActions from './Actions'
import CuiUserMenu from './UserMenu'
// import CuiFavPages from './FavPages'
import apiClient from '@/services/axios'
const API_HOST = process.env.VUE_APP_API_URL || 'https://dev-api-cms.kmbmedia.id'
export default {
  methods: {
    clearCache() {
      const vm = this
      return apiClient({
        method: 'get',
        url: `${API_HOST}/clear-cache`,
      })
        .then((res) => {
          if (res.data.status) {
            vm.$notification.success({
              message: 'Cache Cleared',
              description: 'Successfully cleared.',
            })
          } else {
            vm.$notification.error({
              message: 'Cache Not Cleared',
              description: 'Failed clear cache.',
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  components: {
    // CuiIssuesHistory,
    CuiProjectManagement,
    // CuiSearch,
    // CuiLanguageSwitcher,
    // CuiActions,
    CuiUserMenu,
    Acl,
    // CuiFavPages,
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
