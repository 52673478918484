<template>
  <div id='app'>
    <localization></localization>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Localization from '@/localization'

export default {
  name: 'app',
  components: { Localization },
  computed: {
    ...mapState(['settings']),
    ...mapState('user', ['authorized']),
    nextRoute() {
      return this.$route.query.redirect || '/'
    },
    currentRoute() {
      return this.$route.path
    },
  },
  created() {
    // @ts-ignore
  },
  mounted() {
    this.$store.dispatch('user/LOAD_CURRENT_ACCOUNT')
  },
  watch: {
    '$store.state.settings.theme': function (theme) {
      this.$store.commit('SET_THEME', { theme })
    },
    authorized(authorized) {
      if (authorized) {
        this.$router.replace(this.nextRoute).then(() => {
          this.$store.dispatch('portal/FETCH', {
            perPage: 1000,
          })
        })
      }
    },
    $route: function (to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
}
</script>
