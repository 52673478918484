import Vue from 'vue'
import Vuex from 'vuex'
import apiClient from '@/services/axios'

Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    data: [],
    detail: {},
    form: {
      name: '',
      description: '',
      permissions: [],
    },
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    CLEAR_DATA(state) {
      state.data = []
    },
    CLEAR_FORM(state) {
      state.form = {
        name: '',
        description: '',
        permissions: [],
      }
    },
  },
  actions: {
    FETCH({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get('/v1/roles', {
            params: payload,
          })
          .then(response => {
            if (response) {
              const newData = response.data.data.map((role) => {
                const permissionsTree = []
                let lastGroup = ''
                let lastIndex = 0
                role.permissions.forEach((permission) => {
                  const group = permission.name.split(' ')[0]
                  const access = permission.name.split(' ')[1]
                  if (group !== lastGroup) {
                    permissionsTree.push({
                      title: group,
                      key: lastIndex,
                      children: [],
                    })
                    lastGroup = group
                    lastIndex++
                  }
                  const groupIndex = permissionsTree.map((permission) => permission.title).indexOf(group)
                  permissionsTree[groupIndex].children.push({
                    ...permission,
                    key: permission.slug,
                    title: access,
                  })
                })
                return {
                  ...role,
                  permissionsTree: permissionsTree,
                  label: role.name,
                  key: role.id,
                }
              })
              commit('SET_STATE', {
                loading: false,
                data: newData,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    FETCH_ONE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/roles/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
                form: response,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    EDIT({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/roles/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
                form: {
                  ...response.data,
                  permissions: response.data.permissions.map((permission) => {
                    return permission.id
                  }),
                },
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    STORE({ commit, dispatch, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })
      return new Promise((resolve) => {
        apiClient
          .post('/v1/roles', rootState.role.form)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    UPDATE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      const form = rootState.role.form

      const newData = {
        ...form,
      }

      return new Promise((resolve) => {
        apiClient
          .put(`/v1/roles/${payload}`, newData)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    DELETE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .delete(`/v1/roles/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
  },
}
