import Vue from 'vue'
import Vuex from 'vuex'
import apiClient from '@/services/axios'

Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    data: [],
    form: {
      name: '',
      portal: [],
      created_by: '',
    },
    detail: {},
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    CLEAR_FORM(state) {
      state.form = {
        name: '',
        portal: [],
        created_by: '',
      }
    },
    CLEAR_DATA(state) {
      state.data = []
    },
  },
  actions: {
    FETCH({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get('/v1/pics', {
            params: payload,
          })
          .then(response => {
            if (response) {
              const output = response.data.data.map((pic) => {
                return {
                  ...pic,
                  key: pic.id,
                  value: pic.name,
                  label: pic.name,
                }
              })
              commit('SET_STATE', {
                loading: false,
                data: output,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    FETCH_ONE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/pics/${payload}`)
          .then(response => {
            if (response) {
              const data = {
                ...response.data,
              }
              commit('SET_STATE', {
                loading: false,
                form: data,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    PICS_SEARCHING({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get('/v1/pics_searching', {
            params: payload,
          })
          .then(response => {
            if (response) {
              const output = response.data.map((pic) => {
                return {
                  ...pic,
                  key: pic.id,
                  value: pic.name,
                  label: pic.name,
                }
              })
              commit('SET_STATE', {
                loading: false,
                data: output,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    GET({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/pics/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
                detail: response.data.data,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    GET_BY_SLUG({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/pics/slug/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
                detail: response.data.data,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    STORE({ commit, dispatch, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })
      return new Promise((resolve) => {
        apiClient
          .post('/v1/pics', rootState.pic.form)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            }
            commit('SET_STATE', {
              loading: false,
            })
            resolve(false)
          })
          .catch(err => console.log(err))
      })
    },
    UPDATE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      const form = rootState.pic.form
      const newData = {
        name: form.name,
      }

      return new Promise((resolve) => {
        apiClient
          .put(`/v1/pics/${payload}`, newData)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    DELETE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .delete(`/v1/pics/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
  },
}
