import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'
import Post from './views/post'
Vue.use(Router)
// Vue.mixin({
//   beforeRouteLeave(to, from, next) {
//     console.log('from.path', from.path, to.path)
//     if (from.path === '/posts/create' || from.path.includes('/posts/edit/')) {
//       const conf = window.confirm('Do you really want to leave? you have unsaved post!')
//       if (conf) {
//         next()
//         console.log('from.path', conf, from.path, to.path)
//       } else {
//         console.log('from.path', conf, from.path, to.path)
//         next(false)
//       }
//     } else {
//       next()
//     }
//   },
// })
const router = new Router({
  base: process.env.BASE_URL,
  // mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // changelog
        {
          path: '/changelog',
          key: 'changelog',
          meta: {
            title: 'Changelog',
          },
          component: () => import('./views/changelog'),
        },
        // HOME
        {
          path: '/',
          key: 'home',
          meta: {
            title: 'Home',
          },
          component: () => import('./views/home'),
        },
        // PROFILE
        {
          path: '/profile',
          key: 'profile',
          meta: {
            title: 'Profile',
          },
          component: () => import('./views/profile'),
        },
        {
          path: '/posts/stat/:id',
          key: 'postStat',
          meta: {
            title: 'Post Statistic',
          },
          component: () => import('./views/post/stat'),
        },
        // POST
        {
          path: 'posts',
          key: 'posts',
          meta: {
            title: 'Posts',
          },
          // component: () => import('./views/post'),
          component: Post,
          children: [
            {
              path: '',
              key: 'postsAll',
              meta: {
                title: 'All Posts',
              },
              component: () => import('./views/post/data'),
            },
            {
              path: 'create',
              key: 'postsCreate',
              meta: {
                title: 'Create New Post',
              },
              component: () => import('./views/post/create'),
            },
            {
              path: 'bulk-published/:id',
              key: 'bulkPublished',
              meta: {
                title: 'Bulk Published',
              },
              component: () => import('./views/post/bulk-published'),
            },
            {
              path: 'edit/:id',
              key: 'postsEdit',
              meta: {
                title: 'Edit Post',
              },
              component: () => import('./views/post/edit'),
            },
          ],
        },
        // EDITOR CHOICES
        {
          path: 'editor-choices',
          key: 'editorChoices',
          meta: {
            title: 'Editor Choices',
          },
          component: () => import('./views/editor-choices'),
          children: [
            {
              path: '',
              key: 'editorChoicesAll',
              meta: {
                title: 'Editor Choices',
              },
              component: () => import('./views/editor-choices/data'),
            },
          ],
        },
        // PAGE
        {
          path: 'pages',
          key: 'pages',
          meta: {
            title: 'Pages',
          },
          component: () => import('./views/page'),
          children: [
            {
              path: '',
              key: 'pagesAll',
              meta: {
                title: 'All Pages',
              },
              component: () => import('./views/page/data'),
            },
            {
              path: 'create',
              key: 'pagesCreate',
              meta: {
                title: 'Create New Page',
              },
              component: () => import('./views/page/create'),
            },
            {
              path: 'edit/:id',
              key: 'pagesEdit',
              meta: {
                title: 'Edit Page',
              },
              component: () => import('./views/page/edit'),
            },
          ],
        },
        // TAG
        {
          path: 'tags',
          meta: {
            title: 'All Tags',
          },
          component: () => import('./views/tag'),
          children: [
            {
              path: '',
              key: 'tagsAll',
              meta: {
                title: 'All Tags',
              },
              component: () => import('./views/tag/data'),
            },
            {
              path: 'edit/:id',
              key: 'tagsEdit',
              meta: {
                title: 'Edit Tag',
              },
              component: () => import('./views/tag/edit'),
            },
          ],
        },
        // Project
        {
          path: 'projects',
          meta: {
            title: 'All Projects',
          },
          component: () => import('./views/project'),
          children: [
            {
              path: '',
              key: 'projectAll',
              meta: {
                title: 'All Projects',
              },
              component: () => import('./views/project/data'),
            },
            {
              path: 'edit/:id',
              key: 'projectsEdit',
              meta: {
                title: 'Edit Project',
              },
              component: () => import('./views/project/edit'),
            },
          ],
        },
        // Client
        {
          path: 'clients',
          meta: {
            title: 'All Clients',
          },
          component: () => import('./views/client'),
          children: [
            {
              path: '',
              key: 'clientAll',
              meta: {
                title: 'All Clients',
              },
              component: () => import('./views/client/data'),
            },
            {
              path: 'edit/:id',
              key: 'clientsEdit',
              meta: {
                title: 'Edit Client',
              },
              component: () => import('./views/client/edit'),
            },
          ],
        },
        // PIC
        {
          path: 'pics',
          meta: {
            title: 'All PICS',
          },
          component: () => import('./views/pic'),
          children: [
            {
              path: '',
              key: 'picAll',
              meta: {
                title: 'All PICS',
              },
              component: () => import('./views/pic/data'),
            },
            {
              path: 'edit/:id',
              key: 'picsEdit',
              meta: {
                title: 'Edit PIC',
              },
              component: () => import('./views/pic/edit'),
            },
          ],
        },
        // CATEGORY
        {
          path: 'categories',
          meta: {
            title: 'All Categories',
          },
          component: () => import('./views/category'),
          children: [
            {
              path: '',
              key: 'categoriesAll',
              meta: {
                title: 'All Categories',
              },
              component: () => import('./views/category/data'),
            },
            {
              path: 'edit/:id',
              key: 'categoriesEdit',
              meta: {
                title: 'Edit Category',
              },
              component: () => import('./views/category/edit'),
            },
          ],
        },
        // MEDIA
        {
          path: 'medias',
          meta: {
            title: 'All Medias',
          },
          component: () => import('./views/media'),
        },
        // PORTAL
        {
          path: 'portals',
          key: 'portals',
          meta: {
            title: 'Portals',
          },
          component: () => import('./views/portal'),
          children: [
            {
              path: '',
              key: 'portalsAll',
              meta: {
                title: 'All Portals',
              },
              component: () => import('./views/portal/data'),
            },
            {
              path: 'create',
              key: 'portalsCreate',
              meta: {
                title: 'Create New Portal',
              },
              component: () => import('./views/portal/create'),
            },
            {
              path: 'edit/:id',
              key: 'portalsEdit',
              meta: {
                title: 'Edit Portal',
              },
              component: () => import('./views/portal/edit'),
            },
          ],
        },
        // USER
        {
          path: 'users',
          meta: {
            title: 'All Users',
          },
          component: () => import('./views/user/'),
          children: [
            {
              path: '',
              key: 'userAll',
              meta: {
                title: 'All Users',
              },
              component: () => import('./views/user/data'),
            },
            {
              path: 'create',
              key: 'userCreate',
              meta: {
                title: 'Create New User',
              },
              component: () => import('./views/user/create'),
            },
            {
              path: 'edit/:id',
              key: 'userEdit',
              meta: {
                title: 'Edit User',
              },
              component: () => import('./views/user/edit'),
            },
          ],
        },
        // ROLE
        {
          path: 'roles',
          meta: {
            title: 'All Roles',
          },
          component: () => import('./views/role/'),
          children: [
            {
              path: '',
              key: 'roleAll',
              meta: {
                title: 'All Roles',
              },
              component: () => import('./views/role/data'),
            },
            {
              path: 'create',
              key: 'roleCreate',
              meta: {
                title: 'Create New Role',
              },
              component: () => import('./views/role/create'),
            },
            {
              path: 'edit/:id',
              key: 'roleEdit',
              meta: {
                title: 'Edit Role',
              },
              component: () => import('./views/role/edit'),
            },
          ],
        },
        // PERMISSION
        {
          path: 'permissions',
          meta: {
            title: 'All Permissions',
          },
          component: () => import('./views/permission/'),
          children: [
            {
              path: '',
              key: 'permissionAll',
              meta: {
                title: 'All Permissions',
              },
              component: () => import('./views/permission/data'),
            },
            {
              path: 'create',
              key: 'permissionCreate',
              meta: {
                title: 'Create New Permission',
              },
              component: () => import('./views/permission/create'),
            },
            {
              path: 'edit/:id',
              key: 'permissionEdit',
              meta: {
                title: 'Edit Permission',
              },
              component: () => import('./views/permission/edit'),
            },
          ],
        },
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },

    // Redirect to 404
    {
      path: '*', redirect: 'auth/404', hidden: true,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.user.authorized) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
