import Vue from 'vue'
import Vuex from 'vuex'
import apiClient from '@/services/axios'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    data: [],
    loading: true,
    form: {},
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    FETCH({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get('/v1/media', {
            params: payload,
          })
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
                data: response.data.data.map((media) => {
                  return {
                    ...media,
                    key: media.id,
                  }
                }),
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    FETCH_ONE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/media/${payload}`)
          .then(response => {
            if (response) {
              const data = {
                ...response.data,
              }
              commit('SET_STATE', {
                loading: false,
                form: data,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    UPDATE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      const form = rootState.media.form
      const newData = {
        name: form.name,
        description: form.description,
        alt: form.alt,
        source: form.source,
      }

      return new Promise((resolve) => {
        apiClient
          .put(`/v1/media/${payload}`, newData)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    STORE({ commit, dispatch, rootState }, payload) {
    },
    DELETE({ commit, dispatch, rootState }, payload) {
      return new Promise((resolve) => {
        apiClient
          .delete(`/v1/media/${payload}`)
          .then(response => {
            if (response) {
              resolve(response)
            } else {
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
  },
  getters: {
    media: state => state,
  },
}
