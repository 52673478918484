import Vue from 'vue'
import Vuex from 'vuex'
import apiClient from '@/services/axios'
import moment from 'moment'
import store from '../index'
import { uniqBy } from 'lodash-es'
import { getFlagEmoji } from '../../lib'
import { max, min } from 'lodash'

Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    data: [],
    detail: {},
    viewStats: {},
    form: {
      title: '',
      excerpt: '',
      body: '',
      embeded_video: '',
      meta_title: '',
      meta_description: '',
      is_published: 0,
      published_at: '',
      post_type: 'post',
      portals: [],
      image_media: {},
      image_media_id: null,
      info_grafik_media: {},
      infografik_media_id: null,
      author_id: null,
      authors: [],
      editor_id: null,
      pic_id: null,
      client_id: null,
      project_id: null,
      medias: [],
      imageMedia: [],
      categories: [],
      tags: [],
      tag_ids: [],
      highlight: false,
      is_premium: false,
      image_meta_description: '',
      image_meta_alt: '',
      image_meta_source: '',
      from_post_id: null,
      from_post: null,
      from_author: null,
    },
    loading: false,
  },
  mutations: {
    SET_INDEX_DATA(state, payload) {
      const stateIndex = state.data.findIndex(f => f.id === `${payload.data.id}`)
      if (typeof state.data[stateIndex] !== 'undefined') {
        state.data[stateIndex] = payload.data
        const stateNew = state
        state = stateNew
      }
    },
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    CLEAR_DATA(state) {
      state.data = []
    },
    CLEAR_VIEWSTATS(state) {
      state.viewstats = {}
    },
    CLEAR_FORM(state) {
      state.form = {
        title: '',
        excerpt: '',
        body: '',
        embeded_video: '',
        meta_title: '',
        meta_description: '',
        is_published: 0,
        post_type: 'post',
        portals: [],
        image_media: {},
        image_media_id: null,
        info_grafik_media: {},
        infografik_media_id: null,
        medias: [],
        imageMedia: [],
        categories: [],
        tags: [],
        authors: [],
        highlight: false,
        is_premium: false,
        author_id: null,
        editor_id: null,
        pic_id: null,
        client_id: null,
        project_id: null,
        editorBy: null,
        authorBy: null,
        image_meta_description: '',
        image_meta_alt: '',
        image_meta_source: '',
        from_post_id: null,
        from_author: null,
      }
    },
  },
  actions: {
    FETCH({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get('/v1/posts', {
            params: payload,
          })
          .then(async response => {
            const errorCounts = []
            if (response) {
              // editing
              const postdata = await Promise.all(
                response.data.data.map(async m => {
                  // @ts-ignore
                  m.key = m.id
                  m.editing = null
                  try {
                    const getdoc = await window.getdoc(parseInt(m.id))
                    let editing = null
                    if (getdoc.exists()) {
                      editing = getdoc.data()
                      m.editing = editing
                      m.editing.by = null
                      try {
                        const userEdit = await store.dispatch('user/FETCH_ONE', editing.userId)
                        m.editing.by = userEdit.data.full_name
                      } catch (error) {
                        console.log('failed fetch user edit')
                      }
                    }
                  } catch (err) {
                    errorCounts.push(1)
                    if (errorCounts.length === 1) {
                      Vue.prototype.$notification.error({
                        message: 'Error',
                        description: err.response && err.response.data && err.response.data.error.message ? err.response.data.error.message : err.message,
                      })
                    }
                  }
                  return m
                }),
              )
              await postdata
              response.data.data = postdata
              resolve(response)
              commit('SET_STATE', {
                loading: false,
                data: response.data.data,
              })
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch((err) => {
            console.log(err)
            Vue.prototype.$notification.error({
              message: 'Error',
              description: err.response && err.response.data && err.response.data.error.message ? err.response.data.error.message : err.message,
            })
          })
      })
    },
    FETCH_ONE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/posts/${payload}`)
          .then(response => {
            if (response) {
              const tags = response.data.tags.map((tag) => {
                return {
                  value: tag.slug,
                  label: tag.name,
                }
              })
              store.commit('tag/SET_STATE', {
                data: tags,
              })
              if (response.data.image_media_id && response.data.image_media_id !== '0' && response.data.image_media_id !== null) {
                let image_media = {}
                store.dispatch('media/FETCH_ONE', response.data.image_media_id).then((media) => {
                  if (media) {
                    image_media = media.data
                  }
                  if (response.data.infografik_media_id) {
                    let info_grafik_media = {}
                    store.dispatch('media/FETCH_ONE', response.data.infografik_media_id).then((media2) => {
                      if (media2) {
                        info_grafik_media = media2.data
                      }

                      const data = {
                        ...response.data,
                        is_published: response.data.is_published ? 1 : 0,
                        categories: response.data.categories.map((category) => {
                          return category.id
                        }),
                        image_media: image_media,
                        info_grafik_media: info_grafik_media,
                        published_at: moment(response.data.published_at),
                        tags: response.data.tags.map((tag) => {
                          return tag.slug
                        }),
                        // authors: response.data.authors.map((author) => {
                        //   return author.id
                        // }),
                        medias: response.data.imageMedia.map((media) => {
                          return media.id
                        }),
                      }
                      commit('SET_STATE', {
                        loading: false,
                        form: data,
                      })
                      resolve(response)
                    })
                  } else {
                    const data = {
                      ...response.data,
                      is_published: response.data.is_published ? 1 : 0,
                      categories: response.data.categories.map((category) => {
                        return category.id
                      }),
                      image_media: image_media,
                      info_grafik_media: {},
                      published_at: moment(response.data.published_at),
                      tags: response.data.tags.map((tag) => {
                        return tag.slug
                      }),
                      // authors: response.data.authors.map((author) => {
                      //   return author.id
                      // }),
                      medias: response.data.imageMedia.map((media) => {
                        return media.id
                      }),
                    }
                    commit('SET_STATE', {
                      loading: false,
                      form: data,
                    })
                    resolve(response)
                  }
                })
              } else {
                const data = {
                  ...response.data,
                  is_published: response.data.is_published ? 1 : 0,
                  categories: response.data.categories.map((category) => {
                    return category.id
                  }),
                  image_media: {},
                  info_grafik_media: {},
                  published_at: moment(response.data.published_at),
                  tags: response.data.tags.map((tag) => {
                    return tag.slug
                  }),
                  // authors: response.data.authors.map((author) => {
                  //   return author.id
                  // }),
                  medias: response.data.imageMedia.map((media) => {
                    return media.id
                  }),
                }
                commit('SET_STATE', {
                  loading: false,
                  form: data,
                })
                resolve(response)
              }
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch((err) => {
            Vue.prototype.$notification.error({
              message: 'Error',
              description: err.response.data.error.message,
            })
          })
      })
    },
    REPOST({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/posts/${payload}`)
          .then(response => {
            if (response) {
              const tags = response.data.tags.map((tag) => {
                return {
                  value: tag.slug,
                  label: tag.name,
                }
              })
              store.commit('tag/SET_STATE', {
                data: tags,
              })

              const repostData = {
                from_post_id: response.data.repostFrom && response.data.repostFrom.length ? response.data.repostFrom[0].id : response.data.id,
                repostFrom: response.data.repostFrom && response.data.repostFrom.length ? response.data.repostFrom[0] : response.data,
              }
              const articleUrl = `${repostData.repostFrom.portals && repostData.repostFrom.portals.length ? 'https://' + repostData.repostFrom.portals[0].domain + '/read/' + repostData.repostFrom.slug : ''}`
              const reposttext = articleUrl ? `<figure class="repostfrom">
                <blockquote>Tulisan ini telah tayang di
                <a target="_blank" href="${articleUrl}" >${repostData.repostFrom.portals[0].domain}</a>
                  ${repostData.repostFrom.authorBy && repostData.repostFrom.authorBy.full_name ? 'oleh ' + repostData.repostFrom.authorBy.full_name : repostData.repostFrom.createdBy && repostData.repostFrom.createdBy.full_name ? 'oleh ' + repostData.repostFrom.createdBy.full_name : ''}
                  pada ${moment(repostData.repostFrom.published_at).format('DD MMM YYYY')}
                </blockquote>
              </figure>` : ''

              response.data.body = response.data.body + reposttext
              response.data.editor_id = null
              response.data.author_id = null
              if (response.data.image_media_id) {
                let image_media = {}
                store.dispatch('media/FETCH_ONE', response.data.image_media_id).then((media) => {
                  if (media) {
                    image_media = media.data
                  }
                  const data = {
                    ...response.data,
                    is_published: 0,
                    published_at: moment(response.data.published_at).format('YYYY-MM-DD HH:mm:ss'),
                    categories: [],
                    portals: [],
                    image_media: image_media,
                    tags: response.data.tags.map((tag) => {
                      return tag.slug
                    }),
                    medias: response.data.imageMedia.map((media) => {
                      return media.id
                    }),
                    ...repostData,
                  }

                  // Delete unique key variables
                  delete data.id
                  delete data.slug
                  commit('SET_STATE', {
                    loading: false,
                    form: data,
                  })
                  resolve(response)
                })
              } else {
                const data = {
                  ...response.data,
                  is_published: 0,
                  categories: [],
                  portals: [],
                  image_media: {},
                  published_at: moment(response.data.published_at).format('YYYY-MM-DD HH:mm:ss'),
                  tags: response.data.tags.map((tag) => {
                    return tag.slug
                  }),
                  medias: response.data.imageMedia.map((media) => {
                    return media.id
                  }),
                  ...repostData,
                }
                delete data.id
                delete data.slug
                commit('SET_STATE', {
                  loading: false,
                  form: data,
                })
                resolve(response)
              }
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch((err) => {
            Vue.prototype.$notification.error({
              message: 'Error',
              description: err.response.data.error.message,
            })
          })
      })
    },
    INSERT_BULK_PUBLISHED({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })
      return new Promise((resolve) => {
        apiClient
          .post('/v1/posts/bulkPublished', payload)
          .then(response => {
            if (response) {
              console.log('payload :>> ', payload)
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    STORE({ commit, dispatch, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })
      rootState.post.form.portals.push(rootState.settings.activePortal)

      return new Promise((resolve, reject) => {
        const form = rootState.post.form
        //

        const data = {
          ...form,
          tags: form.tag_ids,
          published_at: form.published_at ? moment(form.published_at).utc(0).format('YYYY-MM-DD HH:mm:ss') : null,
        }
        apiClient
          .post('/v1/posts', data)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch((err) => {
            reject(err)
            Vue.prototype.$notification.error({
              message: 'Error',
              description: err.response.data.map(m => m.message).join(', '),
            })
          })
      })
    },
    UPDATE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })
      const form = rootState.post.form
      const newData = {
        title: form.title,
        excerpt: form.excerpt,
        body: form.body,
        embeded_video: form.embeded_video,
        meta_title: form.meta_title,
        meta_description: form.meta_description,
        is_published: form.is_published,
        published_at: form.published_at ? moment(form.published_at).utc(0).format('YYYY-MM-DD HH:mm:ss') : null,
        portals: [form.portals[0].id],
        image_media_id: form.image_media_id || null,
        infografik_media_id: form.infografik_media_id || null,
        medias: form.medias || [],
        categories: form.categories,
        tags: form.tag_ids,
        highlight: form.highlight,
        is_premium: form.is_premium,
        post_type: form.post_type,
        // authors: form.authors,
        author_id: form.author_id,
        editor_id: form.editor_id,
        pic_id: form.pic_id,
        client_id: form.client_id,
        project_id: form.project_id,
        image_meta_description: form.image_meta_description,
        image_meta_alt: form.image_meta_alt,
        image_meta_source: form.image_meta_source,
      }

      return new Promise((resolve, reject) => {
        apiClient
          .put(`/v1/posts/${payload}`, newData)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch((err) => {
            reject(err)
            Vue.prototype.$notification.error({
              message: 'Error',
              description: err.response.data.map(m => m.message).join(', '),
            })
          })
      })
    },
    DELETE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .delete(`/v1/posts/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch((err) => {
            Vue.prototype.$notification.error({
              message: 'Error',
              description: err.response.data.error.message,
            })
          })
      })
    },
    // Public
    PUBLIC_FETCH({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get('/v1/public/posts', {
            params: payload,
          })
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
                data: response.data.data.map((post) => {
                  return {
                    ...post,
                    key: post.id,
                  }
                }),
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch((err) => {
            Vue.prototype.$notification.error({
              message: 'Error',
              description: err.response.data.error.message,
            })
          })
      })
    },
    // viewstats
    VIEWSTAT_FETCH({ commit, dispatch, rootState }, payload) {
      return new Promise((resolve) => {
        apiClient.get(`/v1/posts/${payload.id}/stat`, {
          params: {
            start: payload.start ? payload.start : null,
            end: payload.end ? payload.end : null,
          },
        }).then(res => {
          const maxVisitor = max(res.data.data.map(m => m[1]))
          const minVisitor = min(res.data.data.map(m => m[1]))
          const minIndex = res.data.data.findIndex(f => f[1] === minVisitor)
          const maxIndex = res.data.data.findIndex(f => f[1] === maxVisitor)
          const avg = res.data.count / res.data.data.length
          //

          dispatch('VIEWSTATS_FETCH_FLAG', {
            id: payload.id,
            start_date: payload.start,
            end_date: payload.end,
            portal_id: payload.portal_id,
          }).then(flag => {
            res.data.flagData = flag
            res.data.statistic = {
              max: maxIndex !== -1 ? res.data.data[maxIndex] : 0,
              min: minIndex !== -1 ? res.data.data[minIndex] : 0,
              avg: avg,
            }
            commit('SET_STATE', {
              loading: false,
              viewStats: res.data,
            })
            resolve(res)
          })
        }).catch(err => {
          Vue.prototype.$notification.error({
            message: 'Error',
            description: err.response.data.error.message,
          })
        })
      })
    },
    VIEWSTATS_FETCH_FLAG({ commit, dispatch, rootState }, payload) {
      let dataFlag = []
      let flagData = []
      return new Promise((resolve, reject) => {
        apiClient.get(`/v1/public/performance/portal/${payload.portal_id}/post/${payload.id}/geoip`, {
          params: {
            start_date: payload.start_date ? payload.start_date : null,
            end_date: payload.end_date ? payload.end_date : null,
            scale: 'daily',
          },
        }).then(flag => {
          const flagUnique = uniqBy(flag.data.data, 1).map(m => m[1])
          dataFlag = flag.data.data
          flagData = flagUnique.map(m => {
            let cityUnique = flag.data.data.filter(f => f[1] === m)
            cityUnique = uniqBy(cityUnique, 3)
            const dataCity = cityUnique.map(c => {
              return {
                city: c[3],
                count: dataFlag.filter(f => f[1] === m && f[3] === c[3]).map(d => d[2]).reduce((a, b) => a + b, 0),
              }
            }).sort((a, b) => b.count - a.count)
            return {
              flag: getFlagEmoji(m),
              code: m,
              count: dataFlag.filter(f => f[1] === m).map(d => d[2]).reduce((a, b) => a + b, 0),
              cities: dataCity,
            }
          })
          dataFlag = flagData.sort((a, b) => b.count - a.count)
          resolve(dataFlag)
        }).catch(() => reject(flagData))
      })
    },
    // trending
    TRENDING_FETCH({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get('/v1/public/posts/trending', {
            params: payload,
          })
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
                data: response.data.map((post) => {
                  return {
                    ...post,
                    key: post.id,
                  }
                }),
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch((err) => {
            Vue.prototype.$notification.error({
              message: 'Error',
              description: err.response.data.error.message,
            })
          })
      })
    },
  },
}
