import Vue from 'vue'
import Vuex from 'vuex'
import apiClient from '@/services/axios'
// import moment from 'moment'
import store from '../index'

Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    data: [],
    detail: {},
    form: {
      title: '',
      excerpt: '',
      body: '',
      is_published: 1,
      portals: [],
      image_media: {},
      image_media_id: null,
    },
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    CLEAR_DATA(state) {
      state.data = []
    },
    CLEAR_FORM(state) {
      state.form = {
        title: '',
        excerpt: '',
        body: '',
        is_published: 1,
        portals: [],
        image_media: {},
        image_media_id: null,
        // tags: [],
      }
    },
  },
  actions: {
    FETCH({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get('/v1/pages', {
            params: payload,
          })
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
                data: response.data.data.map((page) => {
                  return {
                    ...page,
                    key: page.id,
                  }
                }),
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    FETCH_ONE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/pages/${payload}`)
          .then(response => {
            if (response) {
              let image_media = {}
              if (response.data.image_media_id) {
                console.log('Ada Image Media')
                store.dispatch('media/FETCH_ONE', response.data.image_media_id).then((media) => {
                  if (media) {
                    image_media = media.data
                  }
                  const data = {
                    ...response.data,
                    is_published: response.data.is_published ? 1 : 0,
                    image_media: image_media,
                  }
                  commit('SET_STATE', {
                    loading: false,
                    form: data,
                  })
                  resolve(response)
                })
              } else {
                console.log('Tidak Ada Image Media')
                const data = {
                  ...response.data,
                  is_published: response.data.is_published ? 1 : 0,
                  image_media: {},
                }
                commit('SET_STATE', {
                  loading: false,
                  form: data,
                })
                resolve(response)
              }
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    STORE({ commit, dispatch, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })
      rootState.page.form.portals.push(rootState.settings.activePortal)

      return new Promise((resolve) => {
        apiClient
          .post('/v1/pages', rootState.page.form)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    UPDATE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      const form = rootState.page.form

      const newData = {
        title: form.title,
        excerpt: form.excerpt,
        body: form.body,
        is_published: form.is_published,
        portals: [form.portals[0].id],
        image_media_id: form.image_media_id || null,
        // tags: form.tags,
      }

      return new Promise((resolve) => {
        apiClient
          .put(`/v1/pages/${payload}`, newData)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    DELETE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .delete(`/v1/pages/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
  },
}
