import Vue from 'vue'
import Vuex from 'vuex'
import apiClient from '@/services/axios'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    data: [],
    portal_bulk_published: [],
    loading: true,
    form: {
      title: '',
      domain: '',
      users: [],
      url: '',
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    CLEAR_DATA(state) {
      state.data = []
    },
    CLEAR_FORM(state) {
      state.form = {
        title: '',
        domain: '',
        users: [],
      }
    },
  },
  actions: {
    FETCH({ commit, dispatch, rootState }, payload) {
      payload.perPage = payload.perPage ? payload.perPage : 1000
      commit('SET_STATE', {
        loading: true,
      })
      return new Promise((resolve) => {
        apiClient
          .get('/v1/portals', {
            params: payload,
          })
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                data: response.data.data.map((portal) => {
                  return {
                    ...portal,
                    users: [],
                    key: portal.id,
                  }
                }),
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    BULK_PUBLISHED({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })
      return new Promise((resolve) => {
        apiClient
          .get('/v1/portals/bulk_published', {
            params: payload,
          })
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                portal_bulk_published: response.data.data.map((bulk_published) => {
                  return {
                    ...bulk_published,
                    key: bulk_published.id,
                  }
                }),
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    INSERT_BULK_PUBLISHED({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })
      return new Promise((resolve) => {
        apiClient
          .post('/v1/portals/bulkPublished', payload)
          .then(response => {
            if (response) {
              console.log('payload :>> ', payload)
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    FETCH_ONE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/portals/${payload}`)
          .then(response => {
            if (response) {
              response.data.url = response.data.domain ? `https://${response.data.domain}` : ''
              commit('SET_STATE', {
                loading: false,
                form: response.data,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    STORE({ commit, dispatch, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .post('/v1/portals', rootState.portal.form)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    UPDATE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      const form = rootState.portal.form
      const newData = {
        ...form,
      }

      return new Promise((resolve) => {
        apiClient
          .put(`/v1/portals/${payload}`, newData)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    DELETE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .delete(`/v1/portals/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
  },
  getters: {
    portal: state => state,
  },
}
