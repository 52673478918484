<template>
  <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
    <span slot="title">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"></i>
    </span>
    <template v-for="item in menuInfo.children">
      <item
        v-if="!item.children && !item.divider && authorized(item.roles, item.permissions, item.portalRequired)"
        :menu-info="item"
        :styles="styles"
        :key="item.key"
      />
      <sub-menu v-if="item.children" :menu-info="item" :styles="styles" :key="item.key" />
    </template>
  </a-sub-menu>
</template>

<script>
// import Acl from '@/components/cleanui/system/ACL'
import { Menu } from 'ant-design-vue'
import Item from './item'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SubMenu',
  components: { Item },
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: Object,
    styles: Object,
    // permissions: Array,
    // roles: Array,
    // portalRequired: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
  },
  methods: {
    authorized(roles, permissions, portalRequired) {
      const role = this.user.role.name
      const userPermissions = this.user.permissions.map((permission) => {
        return permission.slug
      })
      const authorizedRole = (roles === undefined || !roles.length) ? true : roles.includes(role)
      const authorizedPermission = (permissions === undefined || !permissions.length) ? true : permissions.some(permission => userPermissions.includes(permission))
      const authorizedPortal = portalRequired ? (this.settings.activePortal !== 'undefined' && this.settings.activePortal !== 0) : true
      const authorized = (authorizedRole && authorizedPermission) && authorizedPortal
      return authorized
    },
  },
}
</script>
