<template>
  <a-dropdown class="dropdown" :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <i class="fe fe-layers" :class="$style.icon"></i>
      <span class="d-inline">{{ user.portals.length && settings.activePortal ? user.portals.filter(portal => portal.id === settings.activePortal)[0].title : 'Portal' }}</span>
    </div>
    <a-menu
      @click="handleClick"
      :class="$style.dropdownList"
      :selected-keys="[settings.activePortal]"
      slot="overlay">
      <a-menu-item-group title="Pilih Portal">
        <a-menu-item v-for="(portal, i) in user.portals" :key="portal.id" class="py-2" :class="[{'border-bottom' : i+1 < user.portals.length }]">
          <h6 class="mb-0">{{ portal.title }}</h6>
          <small class="text-muted">{{ portal.domain }}</small>
        </a-menu-item>
      </a-menu-item-group>
    </a-menu>
  </a-dropdown>
</template>
<style lang="scss" scoped>
.ant-dropdown-menu {
  max-height: calc(100vh - 100px) !important;
  overflow: auto !important;
}
</style>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['settings', 'user']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  methods: {
    handleClick(e) {
      const key = e.key
      this.$store.commit('CHANGE_SETTING', { setting: 'activePortal', value: key })
    },
  },
  watch: {
    loading(loading) {
      if (!loading && !this.settings.activePortal) {
        this.$store.commit('CHANGE_SETTING', { setting: 'activePortal', value: this.user.portals[0].id })
      }
    },
  },
}
</script>
