export const getMenuData = [
  {
    title: 'Home',
    key: 'Home',
    icon: 'fe fe-home',
    url: '/',
  },
  {
    category: true,
    title: 'Compose',
    portalRequired: true,
  },
  {
    title: 'Posts',
    key: 'posts',
    icon: 'fe fe-file-text',
    url: '/posts',
    portalRequired: true,
    children: [
      {
        title: 'All Posts',
        key: 'posts',
        url: '/posts',
      },
      {
        title: 'Create New',
        key: 'postsCreate',
        url: '/posts/create',
      },
    ],
  },
  {
    title: 'Editor Choices',
    key: 'editor-choices',
    icon: 'fe fe-heart',
    url: '/editor-choices',
    portalRequired: true,
    // children: [
    //   {
    //     title: 'All Posts',
    //     key: 'posts',
    //     url: '/posts',
    //   },
    //   {
    //     title: 'Create New',
    //     key: 'postsCreate',
    //     url: '/posts/create',
    //   },
    // ],
  },
  {
    title: 'Pages',
    key: 'pages',
    icon: 'fe fe-flag',
    url: '/pages',
    portalRequired: true,
    children: [
      {
        title: 'All Pages',
        key: 'pages',
        url: '/pages',
      },
      {
        title: 'Create New',
        key: 'pagesCreate',
        url: '/pages/create',
      },
    ],
  },
  {
    title: 'Categories',
    key: 'categories',
    icon: 'fe fe-folder',
    url: '/categories',
    portalRequired: true,
  },
  {
    title: 'Tags',
    key: 'tags',
    icon: 'fe fe-tag',
    url: '/tags',
    portalRequired: true,
  },
  {
    title: 'Projects',
    key: 'projects',
    icon: 'fe fe-folder',
    url: '/projects',
    portalRequired: true,
    roles: ['Editor Daerah'],
  },
  {
    title: 'Clients',
    key: 'clients',
    icon: 'fe fe-folder',
    url: '/clients',
    portalRequired: true,
    roles: ['Editor Daerah'],
  },
  {
    title: 'PICS',
    key: 'pics',
    icon: 'fe fe-folder',
    url: '/pics',
    portalRequired: true,
    roles: ['Editor Daerah'],
  },
  {
    title: 'Medias',
    key: 'media',
    icon: 'fe fe-image',
    url: '/medias',
    portalRequired: true,
  },
  // MASTER DATA
  {
    category: true,
    title: 'Master Data',
    roles: ['administrator', 'superadmin'],
  },
  {
    title: 'Portals',
    key: 'portals',
    icon: 'fe fe-layers',
    url: '/portals',
    permissions: ['portals-index', 'portals-create', 'portals-edit', 'portals-store', 'portals-update', 'portals-destroy', 'portals-show'],
  },
  {
    title: 'User & Access',
    icon: 'fe fe-users',
    roles: ['administrator', 'superadmin'],
    children: [
      {
        title: 'Users',
        key: 'users',
        url: '/users',
        permissions: ['users-index', 'users-create', 'users-edit', 'users-store', 'users-update', 'users-destroy', 'users-show'],
      },
      {
        title: 'Roles',
        key: 'roles',
        url: '/roles',
        permissions: ['roles-index', 'roles-create', 'roles-edit', 'roles-store', 'roles-update', 'roles-destroy', 'roles-show'],
      },
      {
        title: 'Permissions',
        key: 'permissions',
        url: '/permissions',
        permissions: ['permissions-index', 'permissions-create', 'permissions-edit', 'permissions-store', 'permissions-update', 'permissions-destroy', 'permissions-show'],
      },
    ],
  },
]
