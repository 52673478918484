import Vue from 'vue'
import Vuex from 'vuex'
import apiClient from '@/services/axios'
import { orderBy } from 'lodash'

Vue.use(Vuex)

function unflatten(arr) {
  const newArr = arr
  const tree = []
  const mappedArr = {}
  let arrElem
  let mappedElem

  // First map the nodes of the array to an object -> create a hash table.
  for (let i = 0, len = newArr.length; i < len; i++) {
    arrElem = newArr[i]
    mappedArr[arrElem.id] = arrElem
    mappedArr[arrElem.id].children = []
  }
  for (const id in mappedArr) {
    if (Object.prototype.hasOwnProperty.call(mappedArr, id)) {
      mappedElem = mappedArr[id]
      const category_parent = mappedElem.category_parent
      if (category_parent.length > 0 && mappedElem.parent_id && mappedArr[mappedElem.parent_id] && mappedArr[mappedElem.parent_id].children) { // If the element is not at the root level, add it to its parent array of children.
        mappedArr[mappedElem.parent_id].children.push(mappedElem)
      } else { // If the element is at the root level, add it to first level elements array.
        tree.push(mappedElem)
      }
    }
  }
  return tree
}

export default {
  namespaced: true,
  state: {
    data: [],
    dataTree: [],
    dataTreeForPost: [],
    form: {
      title: '',
      description: '',
      portals: [],
      image_media_id: '',
      image_media: {},
      parent_id: null,
    },
    detail: {},
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    CLEAR_FORM(state) {
      state.form = {
        title: '',
        description: '',
        portals: [],
        image_media_id: '',
        image_media: {},
        parent_id: null,
      }
    },
    CLEAR_DATA(state) {
      state.data = []
    },
  },
  actions: {
    FETCH({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get('/v1/categories', {
            params: payload,
          })
          .then(response => {
            if (response) {
              // sort
              const orderDataTree = unflatten(response.data.data.map((category) => {
                return {
                  ...category,
                  key: category.id,
                  value: category.id,
                  label: category.title,
                  image_media: {},
                }
              }))
              const dataTreeForPost = orderBy(orderDataTree, ['label'], ['asc'])
              const output = response.data.data.map((category) => {
                return {
                  ...category,
                  key: category.id,
                  value: category.id,
                  label: category.title,
                  image_media: {},
                }
              }).sort((a, b) => a.label < b.label ? -1 : (a.label > b.label ? 1 : 0))
              commit('SET_STATE', {
                loading: false,
                data: output,
                dataTree: dataTreeForPost,
                dataTreeForPost: dataTreeForPost,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    FETCH_ONE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/categories/${payload}`)
          .then(response => {
            if (response) {
              const data = {
                ...response.data,
                image_media: {},
              }
              commit('SET_STATE', {
                loading: false,
                form: data,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    STORE({ commit, dispatch, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })
      rootState.category.form.portals.push(rootState.settings.activePortal)

      return new Promise((resolve) => {
        apiClient
          .post('/v1/categories', rootState.category.form)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    UPDATE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      const form = rootState.category.form
      const portals = rootState.settings.activePortal
      const newData = {
        title: form.title,
        description: form.description,
        image_media_id: form.image_media_id,
        parent_id: form.parent_id,
        portals: portals,
      }

      return new Promise((resolve) => {
        apiClient
          .put(`/v1/categories/${payload}`, newData)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    DELETE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .delete(`/v1/categories/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
  },
}
