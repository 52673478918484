import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import apiClient from '@/services/axios'
import store from 'store'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    id: '',
    uuid: '',
    username: '',
    email: '',
    full_name: '',
    phone: '',
    bio: '',
    role: {},
    permissions: [],
    active: false,
    authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
    loading: true,
    data: [],
    form: {
      username: '',
      email: '',
      password: '',
      password_confirmation: '',
      portals: [],
      roles: [],
      full_name: '',
      phone: '',
      active: 1,
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    CLEAR_DATA(state) {
      state.data = []
    },
    CLEAR_FORM(state) {
      state.form = {
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
        portals: [],
        roles: [],
        full_name: '',
        phone: '',
        active: 1,
      }
    },
  },
  actions: {
    FETCH({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get('/v1/users', {
            params: payload,
          })
          .then(response => {
            if (response) {
              // console.log(response)
              commit('SET_STATE', {
                loading: false,
                data: response.data.data.map((user) => {
                  return {
                    ...user,
                    key: user.id,
                  }
                }),
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    FORGOTPASSWORD({ commit, dispatch, rootState }, { payload }) {
      const { email } = payload
      commit('SET_STATE', {
        loading: true,
      })
      return new Promise((resolve) => {
        apiClient.post('forgotPassword', {
          email,
        }).then(ok => {
          Vue.prototype.$notification.success({
            message: 'Success',
            description: 'Please check reset password link on your email!',
            duration: 30000,
          })
        })
      })
    },
    LOGIN({ commit, dispatch, rootState }, { payload }) {
      const { email, password } = payload
      commit('SET_STATE', {
        loading: true,
      })
      return new Promise((resolve) => {
        apiClient
          .post('/auth', {
            email,
            password,
          })
          .then(response => {
            if (response) {
              const { token } = response.data
              if (token) {
                store.set('accessToken', token)
              }
              dispatch('LOAD_CURRENT_ACCOUNT').then(() => {
                Vue.prototype.$notification.success({
                  message: 'Logged In',
                  description: 'You have successfully logged in.',
                })
                commit('SET_STATE', {
                  loading: false,
                })
                resolve(response.data)
              })
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => {
            console.log(err)
            commit('SET_STATE', {
              loading: false,
            })
          })
      })
    },
    REGISTER({ commit, dispatch, rootState }, { payload }) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .post('/register', payload)
          .then(response => {
            commit('SET_STATE', {
              loading: false,
            })
            resolve(response)
          })
          .catch(error => {
            commit('SET_STATE', {
              loading: false,
            })
            resolve(error)
          })
      })
    },
    LOAD_CURRENT_ACCOUNT({ commit, dispatch, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        return apiClient
          .get('/profile')
          .then(response => {
            if (response) {
              const { accessToken, roles, id, uuid, username, imgMedia, bannerMedia, email, full_name, phone, bio, active, portals, twitter_account, linkedin_account, instagram_account } = response.data

              // Assign role & permission
              let role = {}
              let permissions = []
              let permissions_id = []
              if (roles.length) {
                role = {
                  id: roles[0].id,
                  name: roles[0].name,
                  description: roles[0].description,
                  permissions: roles[0].permissions,
                }
                if (role.permissions.length) {
                  permissions = role.permissions
                  permissions_id = permissions.map(val => val.id)
                }
              }
              // Assign access token
              if (accessToken) {
                store.set('accessToken', accessToken)
              }
              commit('SET_STATE', {
                id,
                uuid,
                username,
                imgMedia,
                bannerMedia,
                email,
                full_name,
                phone,
                bio,
                role,
                permissions,
                permissions_id,
                active,
                authorized: true,
                portals,
                twitter_account,
                linkedin_account,
                instagram_account,
                loading: false,
              })
              resolve(response)
            } else {
              // console.log('not authorized')
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => {
            commit('SET_STATE', {
              loading: false,
            })
            console.log(err)
          })
      })
    },
    LOGOUT({ commit, rootState }) {
      // const logout = mapAuthProviders[rootState.settings.authProvider].logout
      return new Promise((resolve) => {
        apiClient
          .get('/logout')
          .then(() => {
            store.remove('accessToken')
            commit('SET_STATE', {
              id: '',
              uuid: '',
              username: '',
              email: '',
              full_name: '',
              phone: '',
              bio: '',
              role: {},
              imgMedia: '',
              permissions: [],
              active: false,
              authorized: false,
              loading: false,
            })
            window.localStorage.clear()
            router.push('/auth/login')
            resolve(true)
          })
          .catch(err => {
            commit('SET_STATE', {
              id: '',
              uuid: '',
              username: '',
              email: '',
              full_name: '',
              phone: '',
              bio: '',
              role: {},
              permissions: [],
              active: false,
              authorized: false,
              loading: false,
            })
            window.localStorage.clear()
            router.push('/auth/login')
            console.log(err)
          })
      })
    },
    // MASTER DATA
    FETCH_ONE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/users/${payload}`)
          .then(response => {
            if (response) {
              // console.log(response)
              commit('SET_STATE', {
                loading: false,
                form: response.data,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    EDIT({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get(`/v1/users/${payload}`)
          .then(response => {
            if (response) {
              // console.log(response)
              commit('SET_STATE', {
                loading: false,
                form: {
                  ...response.data,
                  roles: response.data.roles.map((role) => {
                    return role.id
                  }),
                  active: response.data.active ? 1 : 0,
                  portals: response.data.portals.map((portal) => {
                    return portal.id
                  }),
                },
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    STORE({ commit, dispatch, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve, reject) => {
        apiClient
          .post('/v1/users', rootState.user.form)
          .then(response => {
            // console.log(response)
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => {
            commit('SET_STATE', {
              loading: false,
            })
            reject(err.response)
          })
      })
    },
    UPDATE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      const form = rootState.user.form

      const newData = {
        ...form,
        active: form.active === 1,
      }

      return new Promise((resolve) => {
        apiClient
          .put(`/v1/users/${payload}`, newData)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    DELETE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .delete(`/v1/users/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
  },
  getters: {
    user: state => state,
  },
}
