<template>
  <component :is="tag" v-if="show && tag !== 'router-link'">
    <slot></slot>
  </component>
  <router-link :to="to" v-else-if="show">
    <slot></slot>
  </router-link>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'CuiACL',
  props: {
    portalRequired: {
      type: Boolean,
      default: false,
    },
    to: {},
    tag: {
      type: String,
      default: 'div',
    },
    redirect: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Array,
      default: () => [],
    },
    permissions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    // ...mapGetters('user', ['user']),
    ...mapState(['settings', 'user']),
  },
  data() {
    return {
      aclDefaultRedirect: '/auth/404',
      // aclRedirect: this.redirect || false,
      // aclRoles: this.roles || [],
      // aclPermissions: this.permissions || [],
      show: false,
    }
  },
  mounted() {
    this.athorize()
  },
  methods: {
    athorize() {
      const defaultRedirect = this.aclDefaultRedirect
      const redirect = this.redirect
      const roles = this.roles
      const role = this.user.role.name
      const userPermissions = this.user.permissions.map((permission) => {
        return permission.slug
      })
      const permissions = this.permissions
      const authorizedRole = roles.includes(role) || !roles.length
      const authorizedPermission = permissions.some(permission => userPermissions.includes(permission)) || !permissions.length
      const authorizedPortal = this.portalRequired ? (this.settings.activePortal !== 'undefined' && this.settings.activePortal !== 0) : true
      const authorized = (authorizedRole || authorizedPermission) && authorizedPortal
      this.show = authorized

      if (!authorized && redirect) {
        const url = typeof redirect === 'boolean' ? defaultRedirect : redirect
        this.$notification.warning({
          message: 'Unauthorized Access',
          description: `You have no rights to access this page. Redirected to ${url}`,
        })
        router.push(url)
      }
    },
  },
}
</script>
