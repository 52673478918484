import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import role from './role'
import permission from './permission'
import portal from './portal'
import media from './media'
import category from './category'
import client from './client'
import tag from './tag'
import post from './post'
import project from './project'
import pic from './pic'
import editorChoices from './editor-choices'
import page from './page'
import settings from './settings'
import profile from './profile'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    role,
    permission,
    portal,
    media,
    category,
    client,
    tag,
    post,
    project,
    pic,
    editorChoices,
    page,
    settings,
    profile,
  },
  state: {},
  mutations: {},
  actions: {},
})
