import Vue from 'vue'
import Vuex from 'vuex'
import apiClient from '@/services/axios'

Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    data: [],
    form: {
      portal_id: null,
      post_id: [],
    },
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    CLEAR_DATA(state) {
      state.data = []
    },
    CLEAR_FORM(state) {
      state.form = {
        portal_id: null,
        post_id: [],
      }
    },
  },
  actions: {
    FETCH({ commit }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .get('/v1/posts/editorChoices', {
            params: payload,
          })
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
                data: response.data.data.map((item) => {
                  return item.id
                }),
              })
              resolve(response.data.data)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch((err) => {
            Vue.prototype.$notification.error({
              message: 'Error',
              description: `error: ${err}`,
            })
          })
      })
    },
    UPDATE({ commit, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })
      const form = rootState.editorChoices.form
      const newData = {
        post_id: form.post_id,
        portal_id: form.portal_id,
      }

      return new Promise((resolve) => {
        apiClient
          .post('/v1/posts/editorChoices', newData)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch((err) => {
            Vue.prototype.$notification.error({
              message: 'Error',
              description: err.response.data.error.message,
            })
          })
      })
    },
    DELETE({ commit, dispatch, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      return new Promise((resolve) => {
        apiClient
          .delete(`/v1/posts/${payload}`)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch((err) => {
            Vue.prototype.$notification.error({
              message: 'Error',
              description: err.response.data.error.message,
            })
          })
      })
    },
  },
}
