import Vue from 'vue'
import Vuex from 'vuex'
import apiClient from '@/services/axios'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    form: {
      avatar: '',
      username: '',
      email: '',
      bio: '',
      twitter_account: '',
      linkedin_account: '',
      instagram_account: '',
      image_media: {},
      banner_media: {},
    },
    password_form: {
      old_password: '',
      password: '',
      password_confirmation: '',
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    CLEAR_FORM(state) {
      state.form = {
        avatar: '',
        username: '',
        email: '',
        bio: '',
      }
    },
    CLEAR_PASSWORD_FORM(state) {
      state.password_form = {
        old_password: '',
        new_password: '',
      }
    },
  },
  actions: {
    UPDATE_PROFILE({ commit, rootState }, payload) {
      commit('SET_STATE', {
        loading: true,
      })

      const form = rootState.profile.form

      return new Promise((resolve) => {
        apiClient
          .put('/profile/', form)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
    UPDATE_PASSWORD({ commit, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })

      const form = rootState.profile.password_form

      return new Promise((resolve) => {
        apiClient
          .put('/changePassword', form)
          .then(response => {
            if (response) {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(response)
            } else {
              commit('SET_STATE', {
                loading: false,
              })
              resolve(false)
            }
          })
          .catch(err => console.log(err))
      })
    },
  },
}
